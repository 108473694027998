import { EVENT_USER_LANGUAGE_CHANGED, EVENT_USER_PROFILE_CHANGED } from '@rio-cloud/rio-user-menu-component';
import { UserManager } from 'oidc-client-ts';
import { config } from '../config';
import { getLocaleFromUrl } from '../hooks/getLocaleFromUrl';
import { extractLanguage } from './lang/lang';
import { getLocale } from './lang/langSlice';
import { configureFetchDisplayMessages } from './lang/services';
import { configureMockUserManager, configureUserManager, createUserManager, SessionRenewedResult } from './login/login';
import { userProfileObtained, userSessionExpired, userSessionRenewed } from './login/loginSlice';
import { handleLoginRedirect } from './login/redirect';
import { attemptInitialSignIn } from './setup/oauth';
import { reportErrorToSentry } from './setup/sentry';
import { store } from './setup/store';
import { trace } from './setup/trace';
import { accessToken } from './tokenHandling/accessToken';
import { accessTokenStored, idTokenStored } from './tokenHandling/tokenSlice';

export interface OAuthConfig {
    onSessionExpired: Function;
    onSessionRenewed: Function;
}

export const main = async (renderApp: Function) => {
    const fetchDisplayMessages = configureFetchDisplayMessages(store);
    const localeFromUrl = getLocaleFromUrl();

    // We want the `<html lang>` attribute to be synced with the
    // language currently displayed
    store.subscribe(() => {
        const lang = extractLanguage(getLocale(store.getState()));
        const html = document.querySelector('html');

        if (html && lang && html.getAttribute('lang') !== lang) {
            html.setAttribute('lang', lang);
        }
    });

    const oauthConfig = {
        onSessionExpired: () => {
            accessToken.discardAccessToken();
            store.dispatch(userSessionExpired());
        },
        onSessionRenewed: (result: SessionRenewedResult) => {
            trace('index.onTokenRenewed', result);

            accessToken.saveAccessToken(result.accessToken);
            store.dispatch(accessTokenStored(result.accessToken));
            store.dispatch(idTokenStored(result.idToken));
            store.dispatch(userProfileObtained(result.profile));

            store.dispatch(userSessionRenewed());

            // You will need to get the user language yourself then
            // you may fetch the suitable messages. Depending
            // on when and from where you fetch the user settings you might
            // want to employ a loading spinner while the request is ongoing.
            fetchDisplayMessages(localeFromUrl.value ?? result.locale);
        },
    } as OAuthConfig;

    const userManager: UserManager = config.login.mockAuthorization
        ? configureMockUserManager(oauthConfig)
        : configureUserManager(oauthConfig, createUserManager());

    const signinSilent = userManager.signinSilent.bind(userManager);
    document.addEventListener(EVENT_USER_LANGUAGE_CHANGED, () => signinSilent());
    document.addEventListener(EVENT_USER_PROFILE_CHANGED, () => signinSilent());

    const login = () =>
        attemptInitialSignIn(userManager)
            .then(() => renderApp())
            // eslint-disable-next-line
            .catch((error: any) => {
                trace('could not start application', error);
                reportErrorToSentry(error);
            });

    if (window.location.href.startsWith(config.login.redirectUri as string)) {
        handleLoginRedirect(userManager).then(login);
    } else {
        void login();
    }
};
