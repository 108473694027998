import qs, { IStringifyOptions } from 'qs';

const QS_OPTIONS = {
    comma: true, // required to parse comma separated string into array
    arrayFormat: 'comma', // required to stringify arrays into comma separated strings
    indices: false, // don't use array indices
    encode: false, // don't encode the entire string as it will be done individually for certain params
    decode: false,
    skipNulls: true, // required to remove empty params
} as IStringifyOptions;

export const toQueryString = (query: unknown) => {
    const queryString = qs.stringify(query, QS_OPTIONS);
    return queryString ? `?${queryString}` : '';
};

export const convertQueryToObject = (queryParams: Record<string, any>, fieldsToFormatAsArray?: string[]) => {
    const parsedQuery = qs.parse(queryParams, QS_OPTIONS);
    fieldsToFormatAsArray?.forEach((field) => {
        if (parsedQuery[field] && typeof parsedQuery[field] === 'string') {
            parsedQuery[field] = [parsedQuery[field]];
        }
    });
    return parsedQuery;
};
