import { createHashRouter, createRoutesFromElements, Route } from 'react-router-dom';

import { ErrorBoundary } from '../components/ErrorBoundary';
import AppLayout from '../layout/AppLayout';
import { suspendPageLoad } from './suspendPageLoad';

// Lazy load pages for better performance and automatically split the bundle accordingly
const Home = suspendPageLoad(() => import('../pages/Homepage'));

export const DEFAULT_ROUTE = '/';

export const routes = [DEFAULT_ROUTE];

export const router = createHashRouter(
    createRoutesFromElements(
        <Route path="/" element={<AppLayout />}>
            <Route path={DEFAULT_ROUTE} errorElement={<ErrorBoundary />} element={<Home />} />
        </Route>
    )
);
