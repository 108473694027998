import { z } from 'zod';

/**
 * Processes API response data, handling both success and error scenarios generically.
 *
 * @param data The raw data received from the API.
 * @param successSchema The Zod schema to validate the success response.
 * @param errorSchema The Zod schema to validate the error response.
 * @param onSuccess Callback to execute on successful validation of the data.
 * @param onError Callback to execute if the data validates as an error.
 */
export const processApiResponse = <T, E>(
    data: unknown,
    successSchema: z.ZodSchema<T>,
    errorSchema: z.ZodSchema<E>,
    // eslint-disable-next-line no-shadow
    onSuccess: (data: T) => void,
    onError: (error: E) => void
) => {
    const successResult = successSchema.safeParse(data);
    if (successResult.success) {
        onSuccess(successResult.data);
    } else {
        const errorResult = errorSchema.safeParse(data);
        if (errorResult.success) {
            onError(errorResult.data);
        } else {
            throw new Error('Data does not match expected structure.');
        }
    }
};
