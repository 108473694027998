import { ApiError, ClosingDay, GetClosingDaysResponse, MappedClosingDay } from './closing-days.type';
import { processApiResponse } from '../utils/processApiResponse';
import { ClosingDaysErrorResponseSchema, GetClosingDaysResponseSchema } from './closing-days.schema';
import { sortBy } from 'lodash';

export const mapToClosingDays = (data: unknown) => {
    let structuredData: MappedClosingDay[] = [];

    processApiResponse<GetClosingDaysResponse, ApiError>(
        data,
        GetClosingDaysResponseSchema, // Schema for success response
        ClosingDaysErrorResponseSchema, // Schema for error response
        (validatedData) => {
            structuredData = validatedData.items.map((i: ClosingDay) => ({
                ...i,
                closingDayType: mapClosingDayType(i),
                isTA: i.closingDayType === 'NO_TRANSPORT_BUT_AVIS',
            }));
        },
        (error) => {
            throw new Error(error.message || 'Error processing API response');
        }
    );

    return sortBy(structuredData, ['date', 'closingDayType', 'country', 'id']);
};

const mapClosingDayType = (closingDay: ClosingDay): 'HOLIDAY' | 'OWN CLOSING DAY' | 'WEEKEND' => {
    switch (closingDay.closingDayType) {
        case 'PUBLIC_HOLIDAY': {
            return 'HOLIDAY';
        }
        case 'NO_TRANSPORT_NO_AVIS':
        case 'TRANSPORT_BUT_NO_AVIS':
        case 'NO_TRANSPORT_BUT_AVIS': {
            return 'OWN CLOSING DAY';
        }
        case 'WEEKEND': {
            return 'WEEKEND';
        }
    }
};
