import { getCurrentIdentityProvider, IdentityProvider } from '../config';

const stylesheetUrls = {
    vw: 'https://uikit.developers.rio.cloud/0.16.3/vw-uikit.css',
    rio: 'https://uikit.developers.rio.cloud/0.16.3/rio-uikit.css',
};

export const loadTheme = () => {
    const idp = getCurrentIdentityProvider(window.location.hostname);
    const currentProvider = idp === IdentityProvider.RIO ? 'rio' : 'vw';
    const stylesheetUrl = stylesheetUrls[currentProvider];

    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href = stylesheetUrl;

    document.head.appendChild(link);
};
