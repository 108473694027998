import { config } from '../../config';
import { trace } from './trace';
import { reportErrorToSentry } from './sentry';
import { UserManager } from 'oidc-client-ts';
import { routeStorage } from '../login/storage';

export const attemptInitialSignIn = async (userManager: UserManager) => {
    const isFreshRedirect = Boolean(param(window, /access_token=([^&]+)/u));
    try {
        const user = await userManager.signinSilent();
        return Promise.resolve(user);
    } catch (error) {
        trace('oidc.signinSilent failed, trying page redirect...', error);

        if (config.login.preventRedirect) {
            // eslint-disable-next-line no-console
            console.warn('[feature/login] redirect prevented due to config. Error was', error);
        } else if (isFreshRedirect) {
            trace('oidc.signinSilent.error', 'redirect prevented due to suspicious signing error', error);
            reportErrorToSentry(
                'could not perform signinSilent. Session expired or third-party cookies are not enabled (incognito mode).',
                error
            );
            routeStorage.discardRoute();
        } else {
            if (shouldStoreRoute()) {
                saveCurrentRoute();
            }
            await userManager.signinRedirect();
        }

        trace('auth problem?', error);
        throw new Error('Need to sign in');
    }
};

const saveCurrentRoute = () => {
    const initialRoute = [window.location.hash, window.location.search].join('').replace(/^#\/?/u, '');
    routeStorage.saveRoute(initialRoute);
    trace('saving initial route', initialRoute);
};

const param = (window: Window, regex: RegExp, defaultValue = null) => {
    let result = defaultValue;
    decodeURI(window.location.href).replace(regex, (_, it) => (result = it));
    return result;
};

const hasNotRedirectUriInPath = () => {
    const redirectUri = config.login.redirectUri;
    const path = window.location.href;
    return redirectUri && !path.startsWith(redirectUri);
};

const hasNotSilentRedirectUriInPath = () => {
    const silentRedirectUri = config.login.silentRedirectUri;
    const path = window.location.href;
    return silentRedirectUri && !path.startsWith(silentRedirectUri);
};

const isNotIframe = () => window?.parent && window.parent === window;

const shouldStoreRoute = () => {
    return isNotIframe() && hasNotRedirectUriInPath() && hasNotSilentRedirectUriInPath();
};
