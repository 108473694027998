const asBool = (value: string | undefined): boolean => value === 'true';

// eslint-disable-next-line no-shadow
export enum IdentityProvider {
    RIO = 'rio',
    VW_QA = 'vw-qa',
    VW = 'vw',
}
export interface ConfigState {
    backend: {
        AUTHENTICATION_SERVICE: string | undefined;
        MENU_SERVICE: string | undefined;
    };
    homeRoute: string | undefined;
    id: string | undefined;
    login: {
        authority: string | undefined;
        clientId: string | undefined;
        oauthScope: string[];
        mockAuthorization: boolean;
        mockLocale: string | undefined;
        mockTenant: string | undefined;
        preventRedirect: boolean;
        redirectUri: string | undefined;
        silentRedirectUri: string | undefined;
    };
    serviceVersion: string;
    serviceEnvironment: string;
    enableMockServer: boolean;
    logoutUri: string | undefined;
    sentryToken: string | undefined;
    sentryModuleName: string;
}

export const getCurrentIdentityProvider = (hostname: String) => {
    if (hostname.startsWith(IdentityProvider.VW_QA)) {
        return IdentityProvider.VW_QA;
    }
    if (hostname.startsWith(IdentityProvider.VW)) {
        return IdentityProvider.VW;
    }
    return IdentityProvider.RIO;
};

const getContext = (): 'web-tms' | 'web-scm' => {
    const queryParams = new URLSearchParams(window.location.search);
    const context = queryParams.get('context');
    if (context === 'webtms') {
        return 'web-tms';
    } else {
        return 'web-scm';
    }
};

export const isWebSCM = () => getContext() === 'web-scm';

export const isWebTMS = () => getContext() === 'web-tms';

export const getMinimumDaysInFutureWhereEditingIsAllowed = (): number => {
    const isWebTms = isWebTMS();
    return isWebTms ? 30 : 14;
};

export const applyConfigForIdp = (): Pick<ConfigState, 'login'> => {
    const idp = getCurrentIdentityProvider(window.location.hostname);
    switch (idp) {
        case IdentityProvider.RIO:
            return {
                login: {
                    authority: import.meta.env.VITE_LOGIN_AUTHORITY,
                    clientId: import.meta.env.VITE_CLIENT_ID,
                    redirectUri: import.meta.env.VITE_LOGIN_REDIRECT_URI,
                    silentRedirectUri: import.meta.env.VITE_LOGIN_SILENT_REDIRECT_URI,
                    oauthScope: ['openid', 'profile', 'email'],
                    mockAuthorization: import.meta.env.DEV,
                    mockLocale: import.meta.env.VITE_LOGIN_MOCK_LOCALE,
                    mockTenant: import.meta.env.VITE_LOGIN_MOCK_TENANT,
                    preventRedirect: asBool(import.meta.env.VITE_LOGIN_PREVENT_REDIRECT),
                },
            };
        case IdentityProvider.VW_QA:
            return {
                login: {
                    authority: import.meta.env.VITE_LOGIN_AUTHORITY_VW_QA,
                    clientId: import.meta.env.VITE_CLIENT_ID_VW_QA,
                    redirectUri: import.meta.env.VITE_LOGIN_REDIRECT_URI_VW_QA,
                    silentRedirectUri: import.meta.env.VITE_LOGIN_SILENT_REDIRECT_URI_VW_QA,
                    oauthScope: ['offline_access', 'openid'],
                    mockAuthorization: import.meta.env.DEV,
                    mockLocale: import.meta.env.VITE_LOGIN_MOCK_LOCALE,
                    mockTenant: import.meta.env.VITE_LOGIN_MOCK_TENANT,
                    preventRedirect: asBool(import.meta.env.VITE_LOGIN_PREVENT_REDIRECT_VW_QA),
                },
            };
        case IdentityProvider.VW:
            return {
                login: {
                    authority: import.meta.env.VITE_LOGIN_AUTHORITY_VW,
                    clientId: import.meta.env.VITE_CLIENT_ID_VW,
                    redirectUri: import.meta.env.VITE_LOGIN_REDIRECT_URI_VW,
                    silentRedirectUri: import.meta.env.VITE_LOGIN_SILENT_REDIRECT_URI_VW,
                    oauthScope: ['offline_access', 'openid'],
                    mockAuthorization: import.meta.env.DEV,
                    mockLocale: import.meta.env.VITE_LOGIN_MOCK_LOCALE,
                    mockTenant: import.meta.env.VITE_LOGIN_MOCK_TENANT,
                    preventRedirect: asBool(import.meta.env.VITE_LOGIN_PREVENT_REDIRECT_VW),
                },
            };
        default:
            throw new Error('Identity provider not found');
    }
};

export const config: ConfigState = {
    backend: {
        AUTHENTICATION_SERVICE: import.meta.env.VITE_AUTHENTICATION_SERVICE,
        MENU_SERVICE: import.meta.env.VITE_MENU_SERVICE,
    },
    homeRoute: import.meta.env.VITE_HOME_ROUTE,
    id: import.meta.env.VITE_ID,
    login: applyConfigForIdp().login,
    serviceVersion: APP_VERSION,
    serviceEnvironment: import.meta.env.MODE,
    enableMockServer: import.meta.env.DEV,
    logoutUri: import.meta.env.VITE_LOGOUT_URI,
    sentryToken: import.meta.env.VITE_SENTRY_DSN,
    sentryModuleName: 'starterTemplate',
};
