import { Log, UserManager } from 'oidc-client-ts';
import { trace } from '../setup/trace';
import { routeStorage } from './storage';

export const handleLoginRedirect = (userManager: UserManager) => {
    const runsInIframe = window?.parent && window.parent !== window;

    if (runsInIframe) {
        // Silent redirect within an <iframe>
        Log.setLogger(console);
        Log.setLevel(Log.INFO);

        // This will propagate the received information provided via
        // query parameters to the parent frame
        trace('signinRedirect via the iframe');
        return userManager.signinCallback();
    } else {
        trace('signinRedirect via parent window');
        return userManager
            .signinRedirectCallback()
            .catch(trace)
            .then(() => {
                redirectToLastRouteOrHome();
            });
    }
};

export const redirectToLastRouteOrHome = () => {
    const initialRoute = routeStorage.getRoute() ? routeStorage.getRoute() : '';
    const redirectPath = initialRoute && initialRoute.startsWith('?') ? initialRoute : `/#/${initialRoute}`;
    window.history.replaceState(null, '', `${window.location.origin}${redirectPath}`);
};
