import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
    ClosingDaysQueryParams,
    DeleteClosingDayRequest,
    GetDunsNumbersResponse,
    MappedClosingDay,
    PostPutClosingDayRequest,
} from './closing-days.type';
import { mapToClosingDays } from './closing-days.mapper';
import { prepareHeaders } from '../../../configuration/tokenHandling/prepareHeaders';
import { buildClosingDaysServiceUrl } from './config';
import { toQueryString } from '../../../utils';

export const closingDaysApi = createApi({
    reducerPath: 'closingDaysApi',
    baseQuery: fetchBaseQuery({ baseUrl: buildClosingDaysServiceUrl(), prepareHeaders }),
    tagTypes: ['ClosingDays'],
    endpoints: (builder) => ({
        deleteClosingDay: builder.mutation<void, DeleteClosingDayRequest>({
            query: (request) => {
                return {
                    url: `/api/holiday/v1/external/closing-days/${request.id}${toQueryString({ date: request.date })}`,
                    method: 'DELETE',
                };
            },
            invalidatesTags: ['ClosingDays'],
        }),
        getClosingDays: builder.query<MappedClosingDay[], ClosingDaysQueryParams>({
            query: (params) => {
                const qs = toQueryString(params);
                return {
                    url: `/api/holiday/v1/external/closing-days${qs}`,
                    method: 'GET',
                };
            },
            transformResponse: (data: unknown) => {
                return mapToClosingDays(data);
            },
            providesTags: ['ClosingDays'],
        }),
        postClosingDay: builder.mutation<void, PostPutClosingDayRequest>({
            query: (body) => ({
                url: '/api/holiday/v1/external/closing-days',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['ClosingDays'],
        }),
        getDunsNumbers: builder.query<GetDunsNumbersResponse, void>({
            query: () => ({
                url: '/api/holiday/v1/internal/duns-numbers',
            }),
        }),
    }),
});

export const {
    useGetClosingDaysQuery,
    useDeleteClosingDayMutation,
    useLazyGetClosingDaysQuery,
    usePostClosingDayMutation,
    useGetDunsNumbersQuery,
} = closingDaysApi;
