import { getCurrentIdentityProvider, IdentityProvider } from '../../../config';

const isDev = import.meta.env.DEV;
export const buildClosingDaysServiceUrl = (): string => {
    if (isDev) {
        return import.meta.env.VITE_CLOSING_DAYS_SERVICE_DEV;
    }
    const idp = getCurrentIdentityProvider(window.location.hostname);
    switch (idp) {
        case IdentityProvider.RIO:
            return import.meta.env.VITE_CLOSING_DAYS_SERVICE_RIO;
        case IdentityProvider.VW_QA:
            return import.meta.env.VITE_CLOSING_DAYS_SERVICE_VW_QA;
        case IdentityProvider.VW:
            return import.meta.env.VITE_CLOSING_DAYS_SERVICE_VW;
        default:
            throw new Error('Could not build base url for closing days service');
    }
};
