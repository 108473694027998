import { supportedLocaleMap } from '../configuration/lang/lang';

export const getLocaleFromUrl = (): {
    value: string | undefined;
} => {
    const localeFromUrl = new URLSearchParams(window.location.search).get('locale') || undefined;
    if (localeFromUrl !== undefined && supportedLocaleMap[localeFromUrl] !== undefined) {
        return { value: supportedLocaleMap[localeFromUrl] };
    }
    return { value: undefined };
};
