import { z } from 'zod';

export const ClosingDaysErrorResponseSchema = z.object({
    errorCode: z.string(),
    message: z.string(),
});

export const ClosingDayTypeSchema = z.enum([
    'PUBLIC_HOLIDAY',
    'NO_TRANSPORT_NO_AVIS',
    'TRANSPORT_BUT_NO_AVIS',
    'NO_TRANSPORT_BUT_AVIS',
    'WEEKEND',
]);

export const PostPutClosingDayRequestSchema = z.object({
    name: z.string(),
    duns: z.string(),
    country: z.string().optional(),
    date: z.string().refine((val) => /^\d{4}-\d{2}-\d{2}$/.test(val), 'Invalid date format'),
    closingDayType: ClosingDayTypeSchema,
});

export const ClosingDaySchema = z.object({
    id: z.string(),
    duns: z.string().optional().nullable(),
    date: z.string().refine((val) => /^\d{4}-\d{2}-\d{2}$/.test(val), 'Invalid date format'),
    name: z.string(),
    closingDayType: ClosingDayTypeSchema,
    modifierType: z.enum(['ADDED', 'OVERRIDDEN', 'MOVED', 'DEACTIVATED']).optional(),
    country: z.string().optional().nullable(),
});

export const ClosingDayMappedSchema = z.object({
    id: z.string(),
    duns: z.string().optional().nullable(),
    date: z.string().refine((val) => /^\d{4}-\d{2}-\d{2}$/.test(val), 'Invalid date format'),
    name: z.string(),
    closingDayType: z.enum(['HOLIDAY', 'OWN CLOSING DAY', 'WEEKEND']),
    isTA: z.boolean(),
    modifierType: z.enum(['ADDED', 'OVERRIDDEN', 'MOVED', 'DEACTIVATED']).optional(),
    country: z.string().optional().nullable(),
});

export const GetClosingDaysResponseSchema = z.object({
    items: z.array(ClosingDaySchema),
});

export const GetDunsNumberResponseDto = z.object({
    account_id: z.string(),
    duns_number: z.string(),
});

export const ClosingDaysQueryParamsSchema = z.object({
    duns: z.string().optional(),
    countries: z.array(z.string()).optional(),
    closingDayTypes: z.array(ClosingDayTypeSchema).optional(),
    startDate: z.string().refine(
        (date) => {
            return /^\d{4}-\d{2}-\d{2}$/.test(date);
        },
        {
            message: 'startDate must be in YYYY-MM-DD format',
        }
    ),
    endDate: z.string().refine(
        (date) => {
            return /^\d{4}-\d{2}-\d{2}$/.test(date);
        },
        {
            message: 'endDate must be in YYYY-MM-DD format',
        }
    ),
});

export const DeleteClosingDayRequestSchema = z.object({
    id: z.string(),
    date: z.string().refine((val) => /^\d{4}-\d{2}-\d{2}$/.test(val), 'Invalid date format'),
});

export const GetDunsNumbersResponseSchema = z.object({
    dunsNumbers: z.array(z.string()),
});
