/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
export const momentLocaleImports = {
    de: () => import('moment/dist/locale/de'),
    en: () => import('moment/dist/locale/en-gb'),
    es: () => import('moment/dist/locale/es'),
    it: () => import('moment/dist/locale/it'),
    cs: () => import('moment/dist/locale/cs'),
    fr: () => import('moment/dist/locale/fr'),
    hu: () => import('moment/dist/locale/hu'),
    pl: () => import('moment/dist/locale/pl'),
    sk: () => import('moment/dist/locale/sk'),
    sv: () => import('moment/dist/locale/sv'),
};
