import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import configReducer from './configSlice';
import loginReducer from '../login/loginSlice';
import langReducer from '../lang/langSlice';
import tokenReducer from '../tokenHandling/tokenSlice';
import appReducer from '../../data/appSlice';
import { closingDaysApi } from '../../services/api/closingDays/closing-days.api';

export const getStore = () =>
    configureStore({
        reducer: {
            config: configReducer,
            lang: langReducer,
            app: appReducer,
            login: loginReducer,
            tokenHandling: tokenReducer,
            [closingDaysApi.reducerPath]: closingDaysApi.reducer,
        },
        middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([closingDaysApi.middleware]),
    });

export const store = getStore();
// Infer the `RootState` and `RootDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type RootDispatch = typeof store.dispatch;

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);
